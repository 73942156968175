.PlayerHead {
  display: grid;
  /* outline: solid 1px red; */
  width: calc(var(--player-container-width) + 20px);
  height: 24px;
  transition: height 0.2s linear;
  overflow: hidden;
  grid-template-rows: 56px 1fr 24px;
  grid-template-columns: 56px 1fr 32px;
  grid-template-areas: "pic eq hider" "progress progress bye";
}

.PlayerHead .control {
  display: none;
}

.PlayerHead .titlebar {
  grid-area: eq;
  display: inline-block;
}

.PlayerHead.open {
  height: 80px;
}

.PlayerHead.open .control {
  height: 80px;
  display: inline-block;
}
.PlayerHead.open .titlebar {
  display: none;
}
/* .PlayerHead * {
  outline: dotted 1px palevioletred;
} */

.PlayerHead .player-photo {
  grid-area: pic;
}
.PlayerHead .player-eq {
  grid-area: eq;
  padding: 4px 0;
}
.PlayerHead .player-hider {
  grid-area: hider;
}
.PlayerHead .player-progress {
  grid-area: progress;
  padding: 0 8px;
  /* outline: solid 1px green; */
  height: 24px;
}
.PlayerHead .player-bye {
  grid-area: bye;
  padding: 0 0;
}
