.progress-outer-label {
  border: dotted 1px gray;
  width: 320px;
  height: 20px;
  background-position: 0 0;
  background-repeat: no-repeat;
  overflow: hidden;
  cursor: text;
}

.progress-outer-label.on {
  box-shadow: 1px 1px 2px #777;
}

.progress-inner-label {
  /* outline: dotted 1px red; */
  white-space: nowrap;
  font-size: 0.8rem;
  mix-blend-mode: exclusion;
  color: white;
  width: fit-content;
  padding: 2px 4px;
  font-family: var(--global-font-family);
}

.progress-inner-label.ellipsed {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
