.ThumbnailGridView {
  --thumbnail-width: 100px;
  --grid-item-height: 160px;
}

.thumb-grid-view-container {
  width: var(--player-container-width);
  /* outline: dotted 2px palevioletred; */
}

.list-view,
.thumbnail-view,
.infinite-scroll-component {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--thumbnail-width), 1fr));
  width: calc(var(--player-container-width) - 20px);
  height: 400px;
  float: left;
  grid-gap: 1px;
  margin-top: 0;
  margin-left: 26px;
  transition: width 0.4s;
  overflow: scroll;
}

.infinite-scroll-component {
  padding: 0;
  margin: 0;
}

.thumbnail-view .thumbnail-item {
  height: var(--grid-item-height);
  margin-top: 12px;
  /* outline: dotted 1px blue; */
  padding: 10px 12px 0 0;
  overflow: hidden;
  width: var(--thumbnail-width);
}
.mobile-search-element {
  height: 0;
  overflow: hidden;
  transition: height 0.2s linear;
}
.mobile-search-element.open {
  height: 56px;
}
.thumbnail-view .thumbnail-item img {
  width: var(--thumbnail-width);
  height: var(--thumbnail-width);
}

.thumbnail-view .thumbnail-item.editing img {
  outline: dotted 2px red;
}

.thumbnail-view .thumbnail-item.selected img {
  outline: solid 3px #37a;
}

.caption-title-line {
  font-size: 0.8rem;
  line-height: 1.2;
}

.caption-footer-line {
  line-height: 1;
  font-size: 0.7rem;
  color: #667;
}
