.RacingClock {
  display: flex;
  height: 100vh;
  transition: all 0.3s linear;
  background-color: rgba(0,0,0,0);
}

.RacingClock.demo {
  height: 200px;
}

.RacingClock.center {
  justify-content: center;
  align-items: center;
}

.RacingClock.cornered {
  justify-content: baseline;
  align-items: baseline;
  padding: 100px 20px;
}

.clock {
  display: flex;
  gap: 2px;
  font-family: "Rubik", sans-serif;
  position: relative;
  flex-grow: 1;
  border: solid 1px #bbb;
  width: 470px;
  max-width: 510px;
  border-radius: 10px;
  padding: 0 20px 10px 40px;
  background-color: #fff;
  opacity: 0.5;
  transition: all 0.2s;
  overflow: hidden;
}
.clock:hover {
  opacity: 0.8;
}
.clock.dropped {
  margin-right: 500px;
}
.credit {
  position: absolute;
  bottom: 4px;
  right: 20px;
  font-size: 0.7rem;
  color: #444;
}
.hh,
.colon,
.mm {
  font-size: 10rem;
}
.am {
  width: 120px;
  text-align: right;
  margin: 0 4px;
  font-size: 4rem;
  padding: 20px 0 0 0;
}
/* .clock * {
  outline: dotted 1px red;
}
*/
.gray {
  color: #ddd;
}
.ss {
  width: 100%;
  position: absolute;
  bottom: 10px;
  right: 0px;
  height: 30px;
  white-space: nowrap;
  /* overflow: hidden; */
  /* outline: dotted 1px red;
  */
}

.ss .ss-debug {
  display: none;
}

.ss.shown .ss-debug {
  display: flex;
  justify-content: center;
  align-items: center;
}


.s2 {
  width: 100%;
  position: absolute;
  bottom: 10px;
  right: 0px;
  height: 30px;
  white-space: nowrap;
  overflow: hidden;
  /* outline: dotted 1px red;
  */
  left: 1440px;
}
.sec {
  width: 20px;
  margin: 0 2px;
  position: absolute;
  opacity: 0.1;
  outline: none;
  color: #999;
}
.sec.active {
  opacity: 1;
  font-weight: 900;
  color: black;
}
.day {
  position: absolute;
  right: 56px;
  top: 8px;
  width: 60px;
}
.dt-day,
.dt-date {
  float: left;
  margin: 0 2px;
  text-transform: uppercase;
  font-weight: bold;
}
.music {
  position: absolute;
  right: 18px;
  top: 4px;
}
