.eq-outer-label {
  width: 400px;
  height: 40px;
  border: solid 1px #ddd;
  /* box-shadow: 1px 1px 3px #444; */
  display: flex;
  gap: 1px;
  position: relative;
  background-color: #ffffff;
  overflow: hidden;
}

.player-equalizer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}

.eq-outer-label .grid-mask {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 400px;
  height: 40px;
  padding: 4px;
  transition: width 0.4s ease-in;
}
