   
.SearchBox ul {
  border-top: solid 1px #bbb;
  list-style: none;
  padding: 0;
  margin: 0;
}
.SearchBox ul li {
  display: flex;
  align-items: center;
  justify-content: baseline;
  height: 24px;
  margin: 4px 0;
  padding: 6px 16px;
  cursor: pointer;
}
.SearchBox ul li .text,
.SearchBox ul li .icon {
  float: left;
  cursor: default;
}
.SearchBox ul li .icon {
  margin: 0 12px;
  color: #bbb;
  font-size: 0.9rem;
}
.SearchBox ul li .close {
  float: right;
}
.SearchBox ul li:hover {
  background-color: #ddd;
}
