.PlayerContainer {
}

.player-container {
  width: 0;
  overflow: hidden;
  transition: width 0.2s linear;
}

.player-container.open {
  width: 545px;
}


.material-icons {
  vertical-align: middle;
}