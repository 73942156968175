.ArticleCard {
  margin-top: auto;
  position: relative;
}

.ArticleCard .article-card-mini-headline {
  position: absolute;
  top: 0;
  padding: 10px;
  mix-blend-mode: exclusion;
  font-weight: 900;
  color: yellow;
  font-family: var(--global-font-family);
}

.ArticleCard:hover .article-card-mini-headline {
  display: none;
}
