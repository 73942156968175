.GridViewToolbar {
  height: 40px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* outline: dotted 1px red; */
  max-width: 500px;
}

.GridViewToolbar .upper-menu-right {
  /* outline: dotted 2px palevioletred; */
  margin-left: auto;
}
