.NewsScroller {
  width: 100vw;
  /* outline: solid 1px red; */
  overflow: hidden;
  position: absolute;
  bottom: 8px;
  /* left: 0; */
}

.NewsScroller.collapsed {
  bottom: -100px;
}

.NewsScroller .scroll-panel {
  display: flex;
  flex-wrap: nowrap;
  gap: 80px;
  align-items: baseline;
  justify-content: baseline;
}

.NewsScroller .scroll-panel.scrolling {
  animation: news-scroll 54s infinite linear;
}

@keyframes news-scroll {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-7600px);
  }
}
