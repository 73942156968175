.HeroPage {
}

.HeroPage .hero-music-player {
  position: absolute;
  right: 20px;
  top: 10px;
}

.HeroPage .hero-settings-button {
  position: absolute;
  left: 20px;
  top: 10px;
}

.hero-menu-button {
  color: yellow;
  mix-blend-mode: exclusion;
}
