.ImagePlayButton {
  position: relative;
  width: 56px;
  height: 56px;
  /* border: dotted 1px green; */
}
.ImagePlayButton img {
  position: absolute;
  top: 0;
  left: 0;
  width: 48px;
  height: 48px;
  transition: border-radius 0.2s;
  margin: 4px;
  border: solid 1px var(--dark-window-color);
}

.ImagePlayButton .play-pause-button .material-icons {
  font-size: 1rem;
}

.ImagePlayButton .play-pause-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.2;
  /* border: dotted 2px red; */
}

.ImagePlayButton:hover .play-pause-button {
  opacity: 1;
}

.spinning-cd {
  animation: album-rotate 4s infinite linear;
  border-radius: 50%;
  box-shadow: none;
  opacity: 0.7;
}

@keyframes album-rotate {
  from {
    transform: rotateZ(0);
  }

  to {
    transform: rotateZ(360deg);
  }
}
