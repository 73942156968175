.FooterNavigation {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  width: calc(var(--player-container-width) - 20px);
  /* outline: dotted 3px rosybrown; */
}

.footer-button {
  display: flex;
  justify-content: center;
}
