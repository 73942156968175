:root {
  --player-container-width: 500px;
  --main-link-color: #222;
  --gray-text-color: #667;
  --accent-shadow-color: rgba(200, 200, 200, 0.4);
  --toolbar-text-color: #ffffff;
  --line-item-normal-color: white;
  --line-item-active-color: antiquewhite;
  --accent-text-color: rgb(255, 0, 128);
  --dark-window-color: #3f51b5;
  --light-window-color: #3f51b5;
  --hilite-text-backcolor: #ffd800;
  --hilite-text-forecolor: #37a;
  --row-even-color: #eef4fe;
  --thumb-item-cell-width: 180px;
  --thumb-item-cell-height: 230px;
  --thumb-item-image-size: 172px;
  --sort-menu-width: inherit;
  --global-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
}

.App.light {
  --dark-window-color: antiquewhite;
  --light-window-color: whitesmoke;
  --toolbar-text-color: #222;
  --hilite-text-backcolor: #37a;
  --hilite-text-forecolor: #ffd800;
}
:root {
  --carousel-title-font: 2rem;
  --carousel-caption-font: 1rem;
  --carousel-line-height: 28px;
  --carousel-max-height: 240px;
}

body {
  margin: 0;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
